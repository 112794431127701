import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/code/src/components/news/layout.jsx";
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
import SEO from '../../components/seo';
import NewsHeader from '../../components/news/header';
import NewsImgWithDescription from '../../components/news/imgWithDescription';
import NewsImg from '../../components/news/img';
import NewsVideo from '../../components/news/video';
export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEO title={"How Airvoice Monitors may be used for volcanic eruptions control and notifications"} description={"One on the Airvoice Monitors is located in the closest proximity to acting Volcano Ebeko, Kuril Islands."} lang={useTranslation().language} link={useI18next().path} mdxType="SEO" />
    <NewsHeader img="how-cityair-monitors-may-be-used-for-volcanic-eruptions-control-and-notifications_1.jpg" tag="news" title="How Airvoice Monitors may be used for volcanic eruptions control and notifications" description="One on the Airvoice Monitors is located in the closest proximity to acting Volcano Ebeko, Kuril Islands." mdxType="NewsHeader" />
    <blank-space />
    <blank-space />
    <p>{`Starting from 2016 and on a regular basis Ebeko erupts columns of ashes and gas-vapour clouds. On April 6, 2021 another ashfall occurred and reached the altitude of 2km with a plume dispersion of 5 km. The satellite picture shows the plume moving towards the city area.`}</p>
    <p>{`But the situation has no reason for concern - the maximum AQI defined was 5 out of 10. And Airvoice monitors registered some sulphur gases already on the eve on April 3 - you may see that on the diagram.`}</p>
    <NewsVideo video="vulkan.mp4" img="vulkan.jpg" mdxType="NewsVideo" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      